import React from 'react';
import {Navigate, Route, Routes} from 'react-router';
import {Main} from "./pages/main/Main";
import {Rules} from "./pages/rules/Rules";
import {Policy} from "./pages/policy/Policy";
import {SignUp} from "./pages/signup/SignUp";
import {MainPage} from "./pages/admin/mainPage/MainPage";
import {Conditions} from "./pages/conditions/Conditions";

const useRoutes = (isAuthenticated) => {
    if (isAuthenticated){
        return(
            <Routes>
                <Route path="/admin/main" element={<MainPage/>} />
            </Routes>
        )
    }else{
        return (
            <Routes>
                <Route path="/" element={<Main/>} />
                <Route path="/policyconf" element={<Policy/>} />
                <Route path="/rules" element={<Rules/>} />
                <Route path="/conditions" element={<Conditions/>} />
                <Route path="/signup" element={<SignUp/>} />
            </Routes>
        )
    }
}

export default useRoutes;
